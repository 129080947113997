<template>
  <div></div>
</template>

<script>
import { loginstatic, weixinstatic } from "apiurl/user";
export default {
  data() {
    return {
      infoObj: {}
    };
  },
  created() {
    this.infoObj = {
      code: this.$route.query.code,
      state: this.$route.query.state
    };
    if (this.$route.path == "/loginLoading/qq") {
      loginstatic(this.infoObj).then(
        res => {
          if (res.success) {
            this.$store.dispatch("saveInfo", res.data.userVO);
            this.$router.replace(res.data.backUrl);
          } else if (res.code == 20001) {
            this.$router.replace("/");
          }
        },
        () => {}
      );
    } else {
      weixinstatic(this.infoObj)
        .then(
          res => {
            if (res.success) {
              this.$store.dispatch("saveInfo", res.data.userVO);
              this.$router.replace(res.data.backUrl);
            }
          },
          () => {}
        )
        .catch(() => {});
    }
  }
};
</script>

<style lang="stylus" scoped>
div
    width 100vw
    height 100vh
</style>
